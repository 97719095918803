import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const mediaListStyle = css`
  display: flex;
  padding-bottom: 30px;
`;

export const mediaItemStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  margin-left: 10px;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const mediaItemLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 44px;
  height: 44px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${theme.colors.grey300};
  border-radius: 22px;
`;
