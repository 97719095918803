import { css } from '@emotion/react';

import { IconFooterNew } from '@/assets/svgs/system';

import * as styles from './FooterMenu.styles';

type FooterMenuSectionItems = {
  title: string;
  links: { title: string; href: string; internal?: boolean; updated?: boolean }[];
};

const SERVICE_ITEMS: FooterMenuSectionItems = {
  title: '서비스',
  links: [
    { title: '제휴카드', href: '/support/partner-card', internal: true },
    { title: '뷰어 다운로드', href: '/support/app/download', internal: true },
    { title: 'CP사이트', href: 'https://cp.ridibooks.com' },
    { title: '리디바탕', href: 'https://ridicorp.com/ridibatang' },
  ],
};

const INQUIRY_ITEMS: FooterMenuSectionItems = {
  title: '기타 문의',
  links: [
    { title: '콘텐츠 제공 문의', href: 'https://help.ridibooks.com/hc/ko/articles/360046896553' },
    { title: '사업 제휴 문의', href: 'https://ridibusiness.com' },
  ],
};

const CORPORATION_ITEMS: FooterMenuSectionItems = {
  title: '회사',
  links: [
    { title: '회사 소개', href: 'https://www.ridicorp.com/' },
    { title: '인재채용', href: 'https://www.ridicorp.com/career/', updated: true },
  ],
};

export const FooterMenuSection = ({ items }: { items: FooterMenuSectionItems }): ReactJSX.Element => (
  <div css={styles.menuSectionStyle}>
    <div css={styles.menuSectionTitleStyle}>{items.title}</div>
    <ul css={styles.menuSectionListStyle}>
      {items.links.map(item => (
        <li css={styles.menuSectionItemStyle} key={item.title}>
          <a
            css={styles.menuSectionItemLinkStyle}
            href={item.href}
            {...(item.internal ? {} : { rel: 'noopener noreferrer', target: '_blank' })}>
            {item.title}
            {item.updated && <IconFooterNew css={styles.menuSectionItemUpdatedStyle} aria-label="NEW" />}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export const FooterMenu = ({ className }: { className?: string }): ReactJSX.Element => (
  <div css={styles.menuStyle} className={className}>
    <FooterMenuSection items={SERVICE_ITEMS} />

    <div css={[styles.menuGroupStyle, css('flex: 2;')]}>
      <FooterMenuSection items={INQUIRY_ITEMS} />
      <FooterMenuSection items={CORPORATION_ITEMS} />
    </div>
  </div>
);
