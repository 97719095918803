import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const businessInfoTitleStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.01em;
  margin-bottom: 10px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-bottom: 0;
      font-size: 11px;
      line-height: 13px;
    `,
  )}
`;

export const businessInfoTitleArrowStyle = css`
  margin-left: 2px;
  font-size: 9px;
`;

export const businessInfoContentStyle = css`
  overflow: hidden;
  margin-bottom: 3px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-bottom: 10px;
    `,
  )}
`;

export const businessInfoListStyle = css`
  margin: -3px 0;
  margin-left: -13px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 10px 0 -3px -13px;
    `,
  )}
`;

const itemTextStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  font-size: 11px;
  line-height: 19px;
  letter-spacing: -0.01em;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 10px;
      line-height: 18px;
    `,
  )};
`;

const dividerStyle = (theme: RIDITheme): SerializedStyles => css`
  content: '';
  display: inline-block;
  background: ${theme.colors.grey200};
  width: 1px;
  height: 9px;
  margin: 2px 6px;
`;

export const infoItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  align-items: center;
  float: left;

  ${itemTextStyle(theme)};

  &::before {
    ${dividerStyle(theme)};
  }
`;

export const infoItemBreakStyle = css`
  clear: both;
`;

export const infoItemConditionalBreakStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      clear: both;
    `,
  )};
`;

export const infoItemTitleStyle = css`
  margin-right: 0.36em;
`;

export const linksListStyle = css`
  display: flex;
  align-items: center;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 10px;
    `,
  )};
`;

export const linksItemStyle = (theme: RIDITheme): SerializedStyles => css`
  ${itemTextStyle(theme)};
  display: inline-flex;
  height: 13px;
  line-height: 13px;
  letter-spacing: -0.01em;

  &::before {
    ${dividerStyle(theme)};
  }

  &:first-of-type {
    &::before {
      display: none;
    }
  }

  &:active,
  &:hover {
    opacity: 0.6;
  }
`;

export const linksEmphasizeStyle = css`
  font-weight: 700;
`;

export const copyrightStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;

  color: ${theme.colors.fillSecondary};
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.01em;
  margin-top: 16px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      margin-top: 9px;
    `,
  )};
`;

export const copyrightIconStyle = css`
  font-size: 11px;
  margin-right: 4px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-right: 2px;
    `,
  )};
`;
