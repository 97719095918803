import { ReactNode, useState } from 'react';

import { IconArrow } from '@/assets/svgs/system';

import * as styles from './BusinessInformation.styles';

const InfoItemBreakPoint = {
  ALWAYS: 2,
  CONDITIONAL: 1,
  NEVER: 0,
} as const;

interface InfoItemProps {
  title: string;
  children: ReactNode;
  breakPoint?: (typeof InfoItemBreakPoint)[keyof typeof InfoItemBreakPoint];
}

const InfoItem = ({ title, children, breakPoint = InfoItemBreakPoint.NEVER }: InfoItemProps): ReactJSX.Element => (
  <div
    css={[
      styles.infoItemStyle,
      breakPoint === InfoItemBreakPoint.ALWAYS && styles.infoItemBreakStyle,
      breakPoint === InfoItemBreakPoint.CONDITIONAL && styles.infoItemConditionalBreakStyle,
    ]}>
    <dt css={styles.infoItemTitleStyle}>{title}</dt>
    <dd>{children}</dd>
  </div>
);

const InfoLink = ({
  className,
  href,
  children,
}: {
  className?: string;
  href: string;
  children: ReactNode;
}): ReactJSX.Element => (
  <li css={styles.linksItemStyle} className={className}>
    <a href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  </li>
);

export const BusinessInformation = ({ className }: { className?: string }): ReactJSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <button type="button" onClick={() => setIsOpen(open => !open)} css={styles.businessInfoTitleStyle}>
        리디(주) 사업자 정보
        <IconArrow size={9} thick={1.2} direction={isOpen ? 'up' : 'down'} css={styles.businessInfoTitleArrowStyle} />
      </button>

      {isOpen && (
        <div css={styles.businessInfoContentStyle}>
          <dl css={styles.businessInfoListStyle}>
            <InfoItem title="대표자" breakPoint={InfoItemBreakPoint.ALWAYS}>
              배기식
            </InfoItem>
            <InfoItem title="사업자 등록번호" breakPoint={InfoItemBreakPoint.NEVER}>
              120-87-27435
            </InfoItem>
            <InfoItem title="통신판매업 신고번호" breakPoint={InfoItemBreakPoint.CONDITIONAL}>
              제 2009-서울강남 35-02139호
            </InfoItem>

            <InfoItem title="이메일" breakPoint={InfoItemBreakPoint.ALWAYS}>
              <a href="mailto:help@ridi.com">help@ridi.com</a>
            </InfoItem>
            <InfoItem title="대표전화" breakPoint={InfoItemBreakPoint.NEVER}>
              <a href="tel:1644-0331">1644-0331</a>
            </InfoItem>
            <InfoItem title="주소" breakPoint={InfoItemBreakPoint.CONDITIONAL}>
              서울시 강남구 역삼동 702-28 어반벤치빌딩 4,10,11층(테헤란로 325)
            </InfoItem>
          </dl>
        </div>
      )}

      <ul css={styles.linksListStyle}>
        <InfoLink href="https://policy.ridi.com/legal/terms">이용약관</InfoLink>
        <InfoLink href="https://policy.ridi.com/legal/privacy" css={styles.linksEmphasizeStyle}>
          개인정보 처리방침
        </InfoLink>
        <InfoLink href="https://policy.ridi.com/legal/youth">청소년보호정책</InfoLink>
        <InfoLink href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1208727435">사업자정보확인</InfoLink>
      </ul>

      <div css={styles.copyrightStyle}>
        <span css={styles.copyrightIconStyle}>©</span>
        RIDI Corp.
      </div>
    </div>
  );
};
