import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const menuStyle = css`
  display: flex;
`;

export const menuGroupStyle = css`
  display: flex;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex: 1;
      flex-direction: column;

      & > * {
        flex: 0 0 auto;
      }

      & > * + * {
        margin-top: 30px;
      }
    `,
  )};
`;

export const menuSectionStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 185px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      max-width: initial;
    `,
  )};
`;

export const menuSectionTitleStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;

  padding: 4px 0;
  margin-bottom: 12px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 14px;
    `,
  )};
`;

export const menuSectionListStyle = css`
  display: flex;
  flex-direction: column;
`;

export const menuSectionItemStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  padding: 6px 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 7px 0;
      font-size: 12px;
      line-height: 14px;
    `,
  )};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const menuSectionItemLinkStyle = css`
  display: inline-flex;
  align-items: center;

  &:active,
  &:hover {
    opacity: 0.6;
  }
`;

export const menuSectionItemUpdatedStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  margin-left: 4px;
  font-size: 14px;
`;
