import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const footerRootStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.bgBase};
`;

export const footerBorderStyle = (theme: RIDITheme): SerializedStyles => css`
  display: block;
  width: 100%;
  height: 1px;
  background: ${theme.colors.grey200};
  border: none;
`;

export const footerWrapperStyle = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  max-width: 1170px;
  margin: 0 auto;
  padding: 0 26px;
  padding-top: 41px;
  padding-bottom: 20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-top: 30px;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 50px;
      flex-direction: column;
    `,
  )};
`;

export const footerContentsStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 4px;
`;

export const footerColumnsStyle = css`
  display: flex;
  flex-direction: row;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex-direction: column;
    `,
  )};
`;

export const footerCustomerServiceStyle = css`
  width: 185px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: auto;
      margin-bottom: 30px;
    `,
  )};
`;

export const footerMenuStyle = css`
  flex: 1;
`;

export const footerBusinessInfoStyle = css`
  margin-top: 60px;
  margin-bottom: 20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 55px;
    `,
  )};
`;

export const footerMediaStyle = css`
  padding: 0 4px;
`;

export const customerServiceListStyle = css`
  display: flex;
  flex-direction: column;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex-direction: row;
    `,
  )};
`;

export const customerServiceItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillSecondary};

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 0;
      flex: 1;
    `,
  )};
`;

export const customerServiceItemLinkStyle = css`
  align-items: center;
  display: flex;

  &:active,
  &:hover {
    opacity: 0.6;
  }
`;

export const customerServiceIconStyle = css`
  margin-right: 6px;
`;
