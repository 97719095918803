import { ReactNode } from 'react';

import {
  IconFooterFacebook,
  IconFooterInstagram,
  IconFooterTiktok,
  IconFooterTwitter,
  IconFooterYoutube,
} from '@/assets/svgs/system';
import { a11y } from '@/components/styles/reset';

import * as styles from './MediaList.styles';

interface MediaItemProps {
  children: ReactNode;
  href: string;
  label: string;
}

export const MediaItem = ({ children, href, label }: MediaItemProps): ReactJSX.Element => (
  <li css={styles.mediaItemStyle}>
    <a css={styles.mediaItemLinkStyle} href={href} rel="noopener noreferrer" target="_blank">
      {children}
      <span css={a11y}>{label}</span>
    </a>
  </li>
);

export const MediaList = ({ className }: { className?: string }): ReactJSX.Element => (
  <ul css={styles.mediaListStyle} className={className}>
    <MediaItem href="https://www.facebook.com/official.ridi" label="페이스북">
      <IconFooterFacebook />
    </MediaItem>

    <MediaItem href="https://instagram.com/ridi_official_/" label="인스타그램">
      <IconFooterInstagram />
    </MediaItem>

    <MediaItem href="https://twitter.com/RIDI_twt" label="트위터">
      <IconFooterTwitter />
    </MediaItem>

    <MediaItem href="https://www.youtube.com/user/ridibooks0331" label="유튜브">
      <IconFooterYoutube />
    </MediaItem>

    <MediaItem href="https://www.tiktok.com/@ridi.official" label="틱톡">
      <IconFooterTiktok />
    </MediaItem>
  </ul>
);
